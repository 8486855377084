<template>
  <div>
    <div class="bg">
      <img src="@/assets/images/about_bg.png" alt="">
      <div class="content">
        <div class="title">关于我们</div>
        <div class="desc">欢迎与更多优秀企业加入AI资管</div>
        <!-- <div class="action">免费试用</div> -->
      </div>
    </div>
    <div class="introduce">
      <img src="@/assets/images/about_introduce.png" alt="">
    </div>
    <div class="company-introduce">
      <div class="name">西安清正智彦信息技术有限公司</div>
      <div class="content">
        <p>西安清正智彦信息技术有限公司是一家专注于“EAM资产全生命周期管理”的互联网科技公司。借助ChatGPT AI人工智能、IOT物联网、RFID、AR/MR、大数据、机器人、云原生等新一代信息技术应用，推出了全新一代“清智资产管理平台”，提供成熟的SaaS云部署和混合云部署应用，可订阅式购买产品和服务。我们产品已应用于政府、企业、高校、制造业、医院等行业，得到用户的认可和好评。 </p>
        <p> 我们以“科技创新，智慧分享”为理念，以“便捷易用、快速见效“为服务目标，帮助客户提高资产管理效率40%以上，降低资产维修成本30%以上，轻快切入“资产数字化，数字资产化”的智能化应用。</p>
      </div>
    </div>
    <div class="map">
      <div class="l-wrap">
        <div class="title">欢迎留下您的联系方式</div>
        <img class="img" src="@/assets/images/map.png" alt="">
      </div>
      <div class="r-wrap">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="form.name" placeholder="请输入姓名" />
          </a-form-model-item>
          <a-form-model-item label="手机" prop="phone">
            <a-input v-model="form.phone" placeholder="请输入手机" />
          </a-form-model-item>
          <a-form-model-item label="企业名称" prop="company_name">
            <a-input v-model="form.company_name" placeholder="请输入企业名称" />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
            <a-button type="primary" @click="submit">
              提交
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div class="kefu">
      <img src="@/assets/images/kefu.png" alt="">
    </div>
    <Footer />
  </div>
</template>

<script>

import axios from 'axios'

import Footer from '@/components/Footer'

export default {
  components: {
    Footer
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        name: '',
        phone: '',
        company_name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入正确的手机号', trigger: 'blur', pattern: /^1[3|4|5|7|8][0-9]\d{8}$/ }
        ],
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {

  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var url = ''
          var protocolStr = document.location.protocol
          if (protocolStr == "http:") {
            console.log("protocol = " + protocolStr)
            url = 'http://party.shuduier.com'
          } 
          if (protocolStr == "https:") {
            console.log("protocol = " + protocolStr)
            url = 'https://party.shuduier.com:8443'
          }

          axios({
            url: `${url}/qzzy`,
            method: 'post',
            data: {
              name: this.form.name,
              mobile: this.form.phone,
              crop: this.form.company_name
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 0) {
              this.$message.success('提交成功')
              this.visible = false
            } else {
              this.$message.error('提交失败')
            }
          }).catch(err => {
            console.log(err)
            this.$message.info(err.message)
          })
        } else {
          console.log('error')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bg{
  position: relative;
  img{
    width: 100%;
    display: block;
  }
  .content{
    position: absolute;
    top: 45%;
    left: 25%;
    .title{
      font-size: 4.6rem;
      font-weight: bold;
      color: #fff;
    }
    .desc{
      font-size: 2.4rem;
      font-weight: 100;
      color: #fff;
      margin: 5px 0 40px;
    }
    .action{
      width: 120px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-image: linear-gradient(to right, #3538FB, #92F0FE);
      border-radius: 8px;
      font-size: 1.6rem;
      color: #fff;
      cursor: pointer;
    }
    .action:hover{
      background-image: linear-gradient(to right, #2225DA, #92D3FE);
    }
  }
}
/deep/ .ant-btn{
  line-height: 1.5;
}
.introduce{
  display: flex;
  justify-content: center;
  margin: 4rem 0;
  img{
    width: 1200px;
    display: block;
  }
}
.company-introduce{
  width: 100rem;
  margin: 0 auto 4rem;
  .name{
    font-size: 2rem;
    color: #020106;
  }
  .content{
    font-size: 1.4rem;
    color: #7F7F7F;
    line-height: 2.4rem;
    margin: 20px 0;
  }
}
.map{
  width: 120rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #F8FBFF;
  background-color: #F8FBFF;
  padding: 4rem 10rem;
  margin-bottom: 4rem;
  .l-wrap{
    .title{
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2rem;
    }
    .img{
      width: 50rem;
      display: block;
    }
  }
  .r-wrap{
    flex-grow: 2;
    margin-top: 50px;
    margin-left: 50px;
  }
}
.kefu{
  display: flex;
  justify-content: center;
  img{
    width: 1200px;
    display: block;
  }
}
</style>